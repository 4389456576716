"use client";
import { useTranslations } from "next-intl";
import { useRouter } from "next-intl/client";
import Link from "next-intl/link";
import Image from "next/image";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import { HiBars3BottomRight } from "react-icons/hi2";
import { useScrollDirection } from "../../hooks/scrollDirection";
import { SiteDetail } from "../../interfaces/site-model";
import Sidebar from "../Sidebar/Sidebar";
import styles from "./Navbar.module.scss";

let links = [
  { path: "/", label: "home" },
  { path: "/about-us", label: "about us" },
  { path: "", label: "our services", children: [] },
  { path: "/our-offers", label: "our offers" },
  { path: "/our-blogs", label: "our blogs" },
  { path: "/contact-us", label: "contact us" },
];

const Navbar = (props: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [language, setLanguage] = useState("");
  const [currentPath, setCurrentPath] = useState("home");
  const pathname = usePathname();
  const t = useTranslations("Header");
  const scrollDirection = useScrollDirection();
  const router = useRouter();

  const siteData: SiteDetail = props.data;
  const services: any[] = props.services;

  const showSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (window.location && window?.location?.pathname.includes("/ar")) {
      document.body.dir = "rtl";
      setLanguage("ar");
    } else {
      document.body.dir = "ltr";

      setLanguage("en");
    }
  }, [language, pathname]);

  useEffect(() => {
    const changeNavbar = () => {
      if (window.scrollY > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", changeNavbar);
  });

  useEffect(() => {
    const setActiveNav = () => {
      if (pathname === "/" || pathname === "/en" || pathname === "/ar") {
        setCurrentPath("home");
      } else if (
        pathname === "/about-us" ||
        pathname === "/en/about-us" ||
        pathname === "/ar/about-us"
      ) {
        setCurrentPath("about us");
      } else if (pathname.includes("our-services")) {
        setCurrentPath("our services");
      } else if (
        pathname === "/our-offers" ||
        pathname === "/en/our-offers" ||
        pathname === "/ar/our-offers"
      ) {
        setCurrentPath("our offers");
      } else if (
        pathname === "/our-blogs" ||
        pathname === "/en/our-blogs" ||
        pathname === "/ar/our-blogs"
      ) {
        setCurrentPath("our blogs");
      } else if (
        pathname === "/contact-us" ||
        pathname === "/en/contact-us" ||
        pathname === "/ar/contact-us"
      ) {
        setCurrentPath("contact us");
      }
    };
    setActiveNav();
  }, [pathname, setCurrentPath]);

  if (services?.length) {
    let serviceParent = links.find((li) => li.label === "our services");
    serviceParent.children = [];
    services.forEach((s) => {
      serviceParent.children.push(s);
    });

    serviceParent.children.sort((a: any, b: any) => {
      return a?.attributes?.order - b?.attributes?.order;
    });
  }

  const switchLocale = () => {
    let newUrl = "";
    if (language === "ar" && pathname === "/ar") {
      newUrl = pathname.replace("/ar", "/");
    } else if (language === "en" && pathname === "/en") {
      newUrl = pathname.replace("/en", "/");
    } else if (language === "ar" && pathname.includes("/ar")) {
      newUrl = pathname.replace("/ar", "");
    } else {
      newUrl = pathname.replace("/en", "");
    }
    return router.replace(newUrl, { locale: language === "ar" ? "en" : "ar" });
  };

  const changeRoute = (slug: string) => {
    return router.replace("/our-services/" + slug);
  };

  return (
    <>
      <header
        className={`${styles.header} ${
          scrollDirection === "down" ? "-top-[136px]" : "top-0"
        }`}
      >
        <div className={`container ${styles.container} px-5`}>
          {/* Left */}
          <div className={styles.logo_wrapper}>
            <div className={styles.logo}>
              <Link href="/" className={styles.logo__link}>
                <Image
                  src={siteData?.site_logo?.data?.attributes?.url}
                  alt={
                    siteData?.site_logo?.data?.attributes?.alternativeText ||
                    "logo"
                  }
                  fill
                  className={styles.logo__image}
                  priority
                />
              </Link>
            </div>
          </div>

          {/* Center */}
          <div className={styles.menu}>
            {links.map(({ label, path, children }, index) => (
              <div className="relative group" key={index}>
                {!children ? (
                  <Link
                    href={path}
                    className={`${styles.menu__link} ${
                      currentPath === label && styles.active
                    } `}
                  >
                    {t(label)}
                  </Link>
                ) : (
                  <button
                    className={`${styles.menu__link} ${
                      currentPath === label && styles.active
                    } capitalize`}
                  >
                    {t(label)}
                  </button>
                )}
                {children?.length && (
                  <div className="absolute rounded-md z-10 shadow-sm hidden bg-[#ffffff]  py-4 px-2 top-[100%] text-gray-800 min-w-[250px] group-hover:flex flex-col">
                    {children.map((service, index: number) => {
                      return (
                        <button
                          key={index}
                          className="hover:bg-gray-200 p-2 rounded-sm text-left"
                          onClick={() => changeRoute(service?.attributes?.slug)}
                        >
                          {service?.attributes?.title}
                        </button>
                      );
                    })}
                  </div>
                )}
              </div>
            ))}
          </div>
          {/* Right */}
          <div className={styles.social_icons}>
            <Link
              target="_blank"
              href={`tel:${siteData?.contact_details?.phone_number}`}
              className={styles.social_icons__icon}
            >
              <Image src="/assets/icons/phone.svg" alt="Phone Icon" fill />
            </Link>
            <Link
              target="_blank"
              href={`https://wa.me/${siteData?.contact_details?.whatsapp_number}`}
              className={styles.social_icons__icon}
            >
              <Image
                src="/assets/icons/whatsapp.svg"
                alt="WhatsApp Icon"
                fill
              />
            </Link>
            <Link
              target="_blank"
              href={siteData?.social_links?.instagram_link || ""}
              className={styles.social_icons__icon}
            >
              <Image
                src="/assets/icons/instagram.svg"
                alt="Instagram Icon"
                fill
              />
            </Link>
            <Link
              target="_blank"
              href={siteData?.social_links?.twitter_link || ""}
              className={styles.social_icons__icon}
            >
              <Image src="/assets/icons/twitter.svg" alt="Twitter Icon" fill />
            </Link>

            <button className={styles.switch} onClick={switchLocale}>
              <Image
                src={
                  language === "en"
                    ? "/assets/icons/language_switch.svg"
                    : "/assets/icons/language_switch_en.svg"
                }
                alt="switch"
                fill
              />
            </button>

            <button
              onClick={showSidebar}
              className={styles.social_icons__nav_toggle}
              aria-label="Toggle Navigation"
            >
              <HiBars3BottomRight />
            </button>
          </div>
        </div>
      </header>
      <Sidebar
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        menu={links}
        data={siteData}
      />
    </>
  );
};

export default Navbar;

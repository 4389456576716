"use client";
import Image from "next/image";
import Link from "next/link";
import { FaPhone } from "react-icons/fa";
import { SiteDetail } from "../../interfaces/site-model";
import styles from "./BottomFixedMenus.module.scss";

interface FooterProps {
  data: SiteDetail;
}

const BottomFixedMenus = ({ data }: FooterProps) => {
  // Construct the WhatsApp link
  function buildWhatsAppMessageLink() {
    let whatsappLink: any = `https://wa.me/${data?.contact_details?.whatsapp_number}`;

    // Open the WhatsApp link in a new tab/window
    window.open(whatsappLink, "_blank");
  }

  return (
    <div>
      <Link
        href={`tel:${data?.contact_details?.phone_number}`}
        target="_blank"
        className="fixed bottom-[80px] right-4 bg-white w-12 h-12 flex items-center justify-center rounded-full"
      >
        <FaPhone className="text-[#5a558e] w-6 h-6" />
      </Link>
      <div className={styles.icon}>
        <div
          onClick={buildWhatsAppMessageLink}
          className={styles.whatsApp_icon}
        >
          <Image
            src="/assets/icons/whatsapp-color-icon.svg"
            alt="whatsapp chat icon"
            fill
          />
        </div>
      </div>
    </div>
  );
};

export default BottomFixedMenus;

import(/* webpackMode: "eager" */ "/tmp/build_5c54f300/app/globals.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_5c54f300/app/google-tag-manager.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_5c54f300/components/BottomFixedMenus/BottomFixedMenus.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_5c54f300/components/Footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_5c54f300/components/Navbar/Navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_5c54f300/node_modules/next-intl/dist/src/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["NextUiProviders"] */ "/tmp/build_5c54f300/providers/NextUiProvider.tsx");
